<template>
  <div class="container">
    <div class="personal__title title">Мои бонусы</div>
    <div class="personal__subtitle title title--second">Доступно бонусов</div>
    <div class="personal__bonuses">{{ bonus }} ₽</div>
    <div class="personal-bonuses">
      <div class="personal-bonuses__message title title--second">
        <template v-if="isSubscribeBonuses">
          Вы подписаны на получение предупреждения о сгорании бонусов
        </template>
        <template v-else>
          Получать предупреждения о сгорании бонусов на Ваш e-mail
          {{ profileEmail }} ?
        </template>
      </div>
      <div class="personal-bonuses__form">
        <div class="personal-bonuses__btn">
          <button
            class="btn"
            @click.prevent="unSubscribeBonuses()"
            v-if="isSubscribeBonuses"
          >
            Отменить подписку
          </button>
          <button class="btn" @click.prevent="subscribeBonuses()" v-else>
            Подписаться
          </button>
        </div>
      </div>
    </div>
  </div>
  <SimpleModal v-if="isShowModal" @close="isShowModal = false" />
</template>

<script>
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch("PERSONAL_SET_BONUSES").then(() => {
      next();
    });
  },

  data() {
    return {
      isShowModal: false,
    };
  },

  computed: {
    bonus() {
      return this.$store.state.personal.bonus;
    },
    isSubscribeBonuses() {
      return this.$store.state.personal.isSubscribe;
    },
    profileEmail() {
      return this.$store.state.profile.email;
    },
  },

  methods: {
    subscribeBonuses() {
      this.$store.dispatch("PERSONAL_SUBSCRIBE_BONUSES").then((response) => {
        this.$store.commit("SET_MESSAGE_MODAL_DATA", response.data);
        this.isShowModal = true;
      });
    },
    unSubscribeBonuses() {
      this.$store.dispatch("PERSONAL_UNSUBSCRIBE_BONUSES").then((response) => {
        this.$store.commit("SET_MESSAGE_MODAL_DATA", response.data);
        this.isShowModal = true;
      });
    },
  },
};
</script>
